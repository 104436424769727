#affiliate-backlink {
  display: none;
  height: 40px;
  box-shadow: 0 2px 14px 0 rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.1);
  background-color: #ffffff;
  @include breakpoint(1) {
    height: 30px;
  }

  a {
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    text-decoration: none;

    .icon-misc-arrow-down {
      margin: 7px 7px 4px 20px;
      font-size: 1.5rem;
      color: #787878;
      font-family: $font-reg;
      &::after {
        content: '\2039';
      }
      @include breakpoint(1) {
        margin: 0 5px 0 14px;
      }
    }

    .back-to {
      font-family: $font-reg;
      font-size: 0.875rem;
      margin: 3px 0 0 0;
      color: #787878;
      &.powerpak-back-to {
        margin: 7px 7px 2px 0;
        @include breakpoint(1) {
          margin: 3px 0 0 0;
        }
      }
      &.uk-back-to {
        margin: 7px 7px 2px 0;
        @include breakpoint(1) {
          margin: 3px 5px 0 0;
        }
      }
    }

    .logo-powerpak {
      margin: 12px 0 3px;
      width: 106px;
      height: 16px;
      object-fit: contain;
      @include breakpoint(1) {
        margin: 7px 0 0 0;
        width: 106px;
        height: 14px;
        object-fit: contain;
      }
    }
    .logo-medscape-uk-univadis {
      margin: 7px 0 3px 0;
      width: auto;
      height: 16px;
      object-fit: contain;
      @include breakpoint(1) {
        margin: 3px 0 0 0;
        font-family: $font-reg;
        font-size: 0.875rem;
        font-weight: 600;
        color: #000000;
      }
    }
  }

  .close-backlink {
    position: absolute;
    width: 27px;
    height: 24px;
    border-radius: 24px;
    right: 12px;
    background-image: url('/pi/global/icons/icon-closee.svg');
    transition: .5s;
    cursor: pointer;
    background-position: center;
    background-size: 18px;
    background-repeat: no-repeat;
  }
}
