.header-specialty-toggle {
	font-size: 1.875rem;
	margin-left: 14px;
	line-height: 1.1em;
	margin-bottom: 4px;
	font-family: $font-thin;
	padding: 0;
	background: none;
	border: none;
	align-self: flex-end;
	outline: none;

	@media (max-width: 1023px) {
		display: none;
	}
	.sp-arrow {
		display: inline-block;
		margin-left: 6px;
		vertical-align: top;
		margin-top: 12px;
		position: static;
		line-height: 0px;

		&:after,
		&:before {
			content: none;
		}
	}
}

.dropdown-view .header-specialty-toggle {
	z-index: $z-index_header + 300;
}

button.header-specialty-toggle:hover {
	color: $hover-blue;
	cursor: pointer;
}

.header-specialty-menu {
	display: none;
	position: absolute;
	left: 0;
	right: 0;
	top: 0;
    overflow: hidden;
    background: #fff;
    padding: 2.5rem 0;
    z-index: $z-index_header + 200;
    padding-top: 8rem;

	&.is-expanded {
		display: block;
	}

	&>.resp-container {
		display: block;
		overflow: hidden;
		text-align: left;
		-webkit-column-width: 17rem;
		   -moz-column-width: 17rem;
		        column-width: 17rem;


	}

	.header-specialty-menu-link {
		display: block;
		text-align: left;
		text-decoration: none;
		margin-bottom: 0.625rem;
	    -webkit-column-break-inside:avoid;
		   -moz-column-break-inside:avoid;
	 		    column-break-inside:avoid;
		color: #000;
		font-size: 1rem;
		white-space: nowrap;
		font-family: $font-light;

		&:hover {
			text-decoration: underline;
		}

		.is-www & {

			&:nth-last-child(5), 
			&:nth-last-child(9) {
				margin-bottom: 1rem;
				padding-bottom: 1.75rem;
			}
		}

		.is-cme & {

			&:nth-last-child(4) {
				margin-top: 3rem;
			}
		}
	}
}

.header-specialty-close-button{
	position: absolute;
	height: 1.5rem;
	width: 1.5rem;
	top: 3.5rem;
	right: 3.5rem;
	z-index: $z-index_header + 300;
	padding: 0;
	background: none;
	border: none;
}


.whiteout-header-dropdown {
	background: #000000;
	opacity: 0;
	visibility:hidden;
	z-index: $z-index_header + 100;
	-moz-opacity: 0.80;
	filter: alpha(opacity=80);
	transition: all .25s ease-in-out;
	-moz-transition: all .25s ease-in-out;
	-webkit-transition: all .25s ease-in-out;
	position:fixed;
	width: 100%;
	margin: 0 auto;
	min-height: 100%;
	height: auto;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
}

.dropdown-view .whiteout-header-dropdown {
	opacity: 0.8;
	visibility:visible;
	transition: all .25s ease-in-out;
	-moz-transition: all .25s ease-in-out;
	-webkit-transition: all .25s ease-in-out;
}
