.header-date {
	font-size: 1.25rem;
	font-family: $font-thin;
	line-height: 1;
	margin-bottom: 8px;
	margin-left: 10px;
	align-self: flex-end;

	@media (max-width: 1023px) {
		display: none;
	}
}

.fr_fr .header-date, .es_es .header-date, .pt_pt .header-date {
	  text-transform: lowercase;
}
