// Proxima Nova - thin
@font-face {
    font-family: 'proxima_nova_ththin';
    src: url("/medcss/fonts/proximanova/thin/ProximaNova-Thin-webfont.woff2") format("woff2");
    font-weight: 100;
    font-style: normal;
    font-display: optional
}

// Proxima Nova - light
@font-face {
    font-family: 'proxima_nova_ltlight';
    src: url("/medcss/fonts/proximanova/light/ProximaNova-Light-webfont.woff2") format("woff2");
    font-weight: 300;
    font-style: normal;
    font-display: optional
}

// Proxima Nova - regular
@font-face {
    font-family: 'proxima_nova_rgregular';
    src: url("/medcss/fonts/proximanova/reg/ProximaNova-Reg-webfont.woff2") format("woff2");
    font-weight: 400;
    font-style: normal;
    font-display: optional
}

// Proxima Nova - semibold
@font-face {
    font-family: 'proxima_nova_ltsemibold';
    src: url("/medcss/fonts/proximanova/semibold/ProximaNova-Sbold-webfont.woff2") format("woff2");
    font-weight: 600;
    font-style: normal;
    font-display: optional
}

// Proxima Nova - bold
@font-face {
    font-family: 'proxima_nova_rgbold';
    src: url("/medcss/fonts/proximanova/bold/ProximaNova-Bold-webfont.woff2") format("woff2");
    font-weight: 700;
    font-style: normal;
    font-display: optional
}

// EB Garamond - latin 
@font-face {
	font-family: 'EB Garamond';
	src: url("/medcss/fonts/eb-garamond/eb-garamond.woff2") format('woff2');
	font-weight: 450;
	font-style: normal;
	unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
	font-display: optional
}

// EB Garamond - latin semibold
@font-face {
	font-family: 'EB Garamond';
	src: url("/medcss/fonts/eb-garamond/eb-garamond.woff2") format('woff2');
	font-weight: 600;
	font-style: normal;
	unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
	font-display: optional
}

// EB Garamond - latin-ext
@font-face {
	font-family: 'EB Garamond';
	src: url("/medcss/fonts/eb-garamond/eb-garamond-ext.woff2") format('woff2');
	font-weight: 450;
	font-style: normal;
	unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
	font-display: optional
}

.eb-garamond { // workaround for cssnano 'discardUnused' bug - can remove if we update to cssnano v.4.0.0 or above
	font-family: 'EB Garamond';
}

// Roboto Condensed - latin
@font-face {
	font-family: 'Roboto Condensed';
	src: url("/medcss/fonts/roboto-condensed/roboto-condensed.woff2") format('woff2');
	font-style: normal;
	font-weight: 400;
	unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
	font-display: optional
}

// Roboto Condensed - latin extended
@font-face {
	font-family: 'Roboto Condensed';
	src: url("/medcss/fonts/roboto-condensed/roboto-condensed-ext.woff2") format('woff2');
	font-style: normal;
	font-weight: 400;
	unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
	font-display: optional
}