.page-header.simple {
	background: #fff;
	padding-top: 0;

	@include breakpoint(2) {
		padding: .75rem 0;
	}

	@include breakpoint(1) {
		padding: .375rem 0;
	}

	.resp-container {justify-content: flex-end;}

	.header-logo {
		height: 2.375rem;
		padding-top: .75rem;
		padding-bottom: .25rem;
		position: absolute;
	    left: 50%;
	    transform: translateX(-50%);
	}

	.user-links {
		line-height: 3.375rem;
		align-self: flex-end;

		svg {
			vertical-align: middle;
		}
	}

	.user-popup-menu {
		line-height: 1.6;
		top: 0;
	}

	#hidden-invite-layer {
		top: 57px;
		left: -55px;
		line-height: 1;
	}
}
