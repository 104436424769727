.mobile-nav-toggle {
	display: none;
}

.mobile-nav-toggle-label {
	@media (min-width: 1024px){
		display: none;
	}

	width: 37px;
	display: block;
	height: 24px;
	position: relative;
	margin-top: 6px;
	@media (max-width: 768px){
		width: 27px;
		height: 18px;
		margin-top: .5rem;
	}

	span {
		transition: all .25s;
		background: #7d86a0;
		position: absolute;
		right: 0;
		left: 0;
		height: 4px;
		@media (max-width: 768px){
			height: 3px;
		}
	}
	span:nth-of-type(2) {
		top: 10px;
		@media (max-width: 768px){
			top: 7px;
		}
	}
	span:nth-of-type(3) {
		top: 20px;
		@media (max-width: 768px){
			top: 14px;
		}
	}

	.header-invite-count {
		position: absolute;
		width: 22px;
		height: 1rem;
		background: $red;
		border-radius: 3px;
		color: #fff;
		text-align: center;
		top: -10px;
		right: -10px;
		font-size: .5rem;
		line-height: 1.125rem;
		padding: 0 0.2rem;
	}
}


.mobile-nav-toggle:checked + label {
	span:nth-of-type(1){
		top: 4px;
		transform: rotate(45deg);
	}
	span:nth-of-type(2){
		top: 4px;
		transform: rotate(-45deg);
	}
	span:nth-of-type(3){
		display: none;
	}
	
	.header-invite-count {
		display: none;
	}
}
