#headerbox {

	.logo {

		&-mscp,
		&-univadis {
			margin: 10px 0 0;
			padding: 0;
			font-size: 0;
		}

		&-mscp {
			width: 75px;
			height: 18px;
			background: url("/pi/global/header/langsel/logo-medscape.svg") no-repeat;
			background-size: 75px auto;
			font-size: 0;
		}

		&-univadis {
			margin: 20px 0 0;
			width: 66px;
			height: 15px;
			background: url("/pi/global/header/langsel/logo-univadis.svg") no-repeat;
			background-size: 66px auto;
		}
	}

	.leaving-edition {

		.logo {

			&-univadis {
				background-size: 125px auto;
				width: 125px;
				height: 28px;
				font-size: 0;
			}

			&-mscp {
				margin: 0 0 -3px 4px;
				background-size: 64px auto;
				width: 64px;
				height: 15px;
				font-size: 0;
			}
		}
	}

	.label-new {
		padding-left: 2px;
		font-family: $font-semi; 
		font-size: 0.625rem; 
		line-height: 0.625rem;
		text-transform: uppercase;
		color: #bf2828;
		cursor: pointer;
	}

	.external {

		&::after {
			content: " ";
			background: url("/pi/global/header/langsel/icon-external-link.svg") no-repeat;
			background-size: 12px auto;
			width: 12px;
			height: 12px;
			position: absolute;
			top: 3px;
			right: 25px;
		}
	}

	.header-edition {
		margin: 10px 0 0;
		padding: 0 0 0 20px;
		background: url("/pi/global/header/langsel/icon-globe.svg") no-repeat;
		background-size: 15px 15px;
		background-position: 0 -1px;
		position: relative;

		@include breakpoint(2) {
			display: none;
		}

		&-link {
			margin: 0;
			cursor: pointer;
		}

		&-menu {
			background: $white;
			position: absolute;
			width: 272px;
			font-family: $font-reg;
			font-style: normal;
			font-weight: normal;
			font-size: 0.875rem;
			line-height: 0.875rem;
			letter-spacing: 0.3px;
			color: $light-black;
			-webkit-box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
			-moz-box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
			box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
			text-align: center;
			top: 25px;
			left: 0px;
			z-index: $z-index_menu + 504;
			border: 1px solid #e8e6e6;
			border-radius: 5px;
			display: none;

			.editions {

				&-mscp {
					padding: 25px 0 8px 25px;
				}

				&-univadis {
					padding: 17px 0 13px 25px;
					border-top: 1px solid #e8e6e6;
				}

				&-mscp,
				&-univadis {
					text-align: center;

					.logo {

						&-mscp,
						&-univadis {
							margin: 0 25px 16px 0;
							padding: 0;
							display: inline-block;
						}
					}

					ul {
						margin: 0;
						padding: 0;
						display: -webkit-flex;
						display: -ms-flexbox;
						display: flex;
						-webkit-flex-direction: row;
						-ms-flex-direction: row;
						flex-direction: row;
						-webkit-flex-wrap: wrap;
						-ms-flex-wrap: wrap;
						flex-wrap: wrap;

						li {
							margin: 0;
							padding: 0 0 12px 0;
							list-style: none;
							text-align: left;
							-webkit-flex: 0 0 122px;
							-ms-flex: 0 0 122px;
							flex: 0 0 122px;

							a {
								position: relative;
							}

							&.is-current {
								font-family: $font-bold;
							}
						}
					}

					.external {

						&::after {
							position: absolute;
							top: 3px;
							right: -19px;
						}
					}
				}

				&-mscp {

					li {

						&.en {
							order: 1;
						}

						&.de {
							order: 3;
						}

						&.es {
							order: 5;
						}

						&.fr {
							order: 2;
						}

						&.pt {
							order: 4;
						}

						&.uk {
							order: 6;

							.label-new { // PPE-400599 - spacing fix
								margin-left: 4px;
							}
						}
					}
				}

				&-univadis {

					li {

						&.fr {
							order: 1;
						}

						&.it {
							order: 2;
						}
					}
				}
			}
		}

		&.active .header-edition-menu {
			-webkit-transition: all 300ms ease-in-out;
			-moz-transition: all 300ms ease-in-out;
			transition: all 300ms ease-in-out;
			display: block;
		}

		&::after {
			content: " ";
			background: url("/pi/global/header/langsel/icon-down-stroke.svg") no-repeat;
			background-size: 12px 6px;
			width: 12px;
			height: 6px;
			position: absolute;
			top: 4px;
			right: -18px;
		}

		&.active {

			&::after {
				transform: rotate(-180deg);
				transition: transform .15s ease;
			}
		}
	}
}

.leaving-edition {
	padding: 45px 50px;
	background: $white;
	border: 0.25px solid #979797;
	box-sizing: border-box;
	border-radius: 8px;
	width: 490px;
	position: fixed;
	top: 50%;
	left: 50%;
	-webkit-box-shadow:0 0 10px rgba(0,0,0,0.4);
	-moz-box-shadow:0 0 10px rgba(0,0,0,0.4); 
	box-shadow:0 0 10px rgba(0,0,0,0.4);
	transform: translate(-50%, -50%);
	-webkit-transition: all 300ms ease-in-out;
	-moz-transition: all 300ms ease-in-out;
	transition: all 300ms ease-in-out;
	z-index: $z-index_layer + 504;
	display: none;

	@include breakpoint(1) {
		padding: 60px 20px 66px;
		max-width: 355px;
	}

	.close-btn {
		background: url("/pi/global/header/langsel/icon-close.svg") no-repeat;
		background-size: 18px auto;
		width: 18px;
		height: 18px;
		font-size: 0;
		position: absolute;
		right: 14px;
		top: 14px;
		cursor: pointer;
	}

	.logo {
	
		&-container {
			margin: 0 auto 27px;
			text-align: center;
			display: -webkit-flex;
			display: -ms-flexbox;
			display: flex;
			-webkit-justify-content: center;
			-ms-flex-pack: center;
			justify-content: center;
			-webkit-align-items: flex-end;
			-ms-flex-align: end;
			align-items: flex-end;
		}

		&-from {
			margin: 0 0 0 2px;
			background: url("/pi/global/header/langsel/icon-from.svg") no-repeat;
			background-size: 25px auto;
			width: 25px;
			height: 10px;
			font-size: 0;
		}
	}

	.desc {
		margin: 0 0 33px;
		padding: 0 15px 0 30px;
		font-family: $font-light;
		font-style: normal;
		font-weight: normal;
		font-size: 1.125rem;
		line-height: 1.375rem;

		@include breakpoint(1) {
			padding: 0;
		}

		.en {
			padding-top: 20px;
		}
	}

	.btn-container {
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
		
		@include breakpoint(1) {
			-webkit-flex-direction: column;
			-ms-flex-direction: column;
			flex-direction: column;
		}

		.continue {
			padding: 12px 16px;
			max-width: 190px;
			background: $mscp-blue;
			border-radius: 3px;
			color: $white;
			font-family: $font-reg;
			font-size: 1rem;
			line-height: 1rem;	
			text-align: center;	
			cursor: pointer;

			@include breakpoint(1) {
				margin: 0 10px 22px;
				max-width: none;
				width: 85%;
			}

			&:hover {
				background: $hover-blue;
				@include hover-ease;
			}
		}

		.return {
			padding: 0 0 0 30px;
			font-family: $font-reg;
			font-size: 1rem;
			line-height: 1rem;	
			text-align: center;	
			color: $mscp-blue;
			cursor: pointer;

			@include breakpoint(1) {
				padding: 0;
			}

			&:hover {
				color: $hover-blue;
				@include hover-ease;
			}
		}
	}
}

.greyout-layer {
	background: #000;
	opacity: 0;
	visibility: hidden;
	z-index: $z-index_header + 100;
	-moz-opacity: 0.65;
	filter: alpha(opacity=65);
	transition: all .25s ease-in-out;
	-moz-transition: all .25s ease-in-out;
	-webkit-transition: all .25s ease-in-out;
	position: fixed;
	width: 100%;
	margin: 0 auto;
	min-height: 100%;
	height: auto;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	
	&.active {
		opacity: 0.65;
		visibility: visible;
		transition: all .25s ease-in-out;
		-moz-transition: all .25s ease-in-out;
		-webkit-transition: all .25s ease-in-out;
	}
}