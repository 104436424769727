@import 'layout';

@import 'components/logo',
		'components/editions',
		'components/date',
		'components/specialty',
		'components/tabs',
		'components/search',
		'components/side-menu',
		'components/user-links',
		'components/slide-out-menu',
		'components/rc',
		'components/backlink',
		'components/language-selection';



@import 'simple',
		'dp-header';


body.modal-open, 
body.modal-open #pageContainer {
	overflow: hidden;
	position: fixed;
	right: 0;
	left: 0;
}

.page-header {
	a,
	a:link,
	a:visited,
	a:hover {
		color: #000;
	}
}

.is-cme .header-invitations-link, 
.is-cme .header-invite-count {
	display: none;
}



