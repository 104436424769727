.header-edition-link {
	font-size: 0.875rem;
	line-height: 0.875rem;
	margin-left: 7px;
	font-family: $font-light;

	&.is-current {
		font-family: $font-bold;
		pointer-events: none;
	}
}

a, a:link, a:visited, a:hover, a:active {color: inherit;}
