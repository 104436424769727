//	File: _base.scss | Author: rmatyas | Modified: 7/2/2018

/*------------------------------------*\
	STYLE GUIDE GLOBAL RESETS
\*------------------------------------*/

body {
	margin: 0;
	padding: 0;
	font-family: $font-reg;
}

a {
	&,
	&:link {
		color: $mscp-blue;
		text-decoration: none;

		&.title {
			color: $title-gray;
		}
	}

	&:visited {
		color: $link-purple;
		text-decoration: none;

		&.title {
			color: $title-gray;
		}
	}

	&:hover {
		color: $hover-blue;
		text-decoration: none;
		@include hover-ease;

		&.title {
			color: $mscp-blue;
			@include hover-ease;
		}
	}

	&:active {
		color: $mscp-blue;
		text-decoration: none;

		&.title {
			color: $title-gray;
		}
	}

	&.title {
		color: $title-gray;
	}
}

b,
strong {
	font-family: $font-bold;
	font-weight: normal;
}

h1, h2, h3, h4, h5, h6 {
	font-family: $font-bold;
	font-weight: normal;
	color: $title-gray;
}

h1 {
	margin: 0;
	padding: 0 0 10px 0;
	font-size: 2.5rem;

	@include breakpoint(2) {
		font-size: 1.75rem;
	}
}

h2 {
	margin: 0;
	padding: 0 0 10px 0;
	font-family: $font-rb;
	font-size: 1.75rem;

	@include breakpoint(2) {
		font-size: 1.375rem;
	}

	sup {
		font-size: 0.9375rem;

		@include breakpoint(2) {
			font-size: 0.8125rem;
		}
	}
}

h3 {
	margin: 0;
	padding: 0 0 10px 0;
	font-family: $font-rb;
	font-size: 1.25rem;

	@include breakpoint(2) {
		font-size: 1rem;
	}
}

.page-body {
	color: $light-black;
}

.teaser {
	margin: 0;
	padding: 0 0 8px;
	font-family: $font-light;
	font-size: 1.125rem;
	display: block;

	@include breakpoint(2) {
		font-size: 1rem;
	}
}

.byline,
.commentcount {
	margin: 0;
	padding: 0;
	font-size: 0.875rem;
	color: $byline-gray;
}

.comments,
.commentcount {
	background: url('/pi/global/icons/icon-comments-gray.svg') no-repeat 2px center;
	background-size:13px auto;
	padding-left:20px;
	display:none;
}

.commentcount {
	margin-left: 5px;
}

.comments.hasComments,
.commentcount.hasComments {
	display: inline;
}

.inactive {
	display: none;
}

.active {
	display: block;
}

// tags
.alerttag,
.newstag, 
.conftag {
	padding: 0 0 3px;
	font-family: $font-rb;
	font-size: 0.875rem;
	text-transform: uppercase;
	display: block;
	color: $red;
}

.conftag {
	color: $green;
}

.cmetag {
	padding: 0 0 10px;
	font-family: $font-rb;
	font-size: 0.875rem;
	text-transform: uppercase;
	color: $red;
	white-space: nowrap;
	display: inline-block;
}

// icons
.video {
	background: url("/pi/global/icons/icon-video-play-btn-gray.svg") no-repeat 2px center;
	background-size: 15px auto;
	padding-left: 20px;
}

.audio {
	background: url("/pi/global/icons/icon-audio-gray.svg") no-repeat 2px center;
	background-size: 15px auto;
	padding-left: 20px;
}

.slide {
	background: url("/pi/global/icons/icon-slideshow-gray.svg") no-repeat 2px center;
	background-size: 15px auto;
	padding-left: 20px;
}

// ads
.rightAd {
	padding: 0 0 20px 0;
	text-align: center;
}

// logout layer - load in _base.scss to suppress flash of text
.layer-whiteout {
	width: 100%;
	height: 100%;
	background: $white;
	visibility: hidden;
	opacity: 0;
	position: absolute;
	z-index:$z-index_layer + 496;
	margin: 0 auto;
	height: auto;
	top: 40px;
	right: 0;
	bottom: 0;
	left: 0;
	visibility: hidden;
	-moz-opacity: 0.80;
	filter: alpha(opacity=80);
	opacity: 0;
	transition: all .25s ease-in-out;
	-moz-transition: all .25s ease-in-out;
	-webkit-transition: all .25s ease-in-out;
}

#layer-logout-confirmation.showLogout {
	visibility: visible;
	opacity: 1;
	top: 40px;
}

#layer-logout-bg.showLogout {
	visibility: visible;
	-webkit-opacity: 0.8;
	opacity: 0.8;
	top: 0;
}

.layer-pop {
	visibility: hidden;
	opacity: 0;
	font-family: $font-light;
	font-size: 1.55em;
	position: absolute;
	display: block !important;
	background-color: $white;
	padding: 30px 0 40px;
	margin: 30px auto 0;
	width: 278px;
	z-index:$z-index_layer + 500;
	left: 50%;
	margin-left: -139px;
	-webkit-box-shadow: 0 1px 1px 4px rgba(0,0,0,.05);
	box-shadow: 0 1px 1px 4px rgba(0,0,0,.05);
	border: 1px solid rgba(0,0,0,.1);
	text-align: center;
	box-sizing: border-box;

	b {
		font-family: $font-semi;
	}

	.layer-content {
		margin: 20px 30px 0;
	}

	.layer-content-group {
		overflow: hidden;
	}
}

.layer-pop-header {
	background: $hover-blue;
	color: $white;
	font-size: 1.26em;
	padding: 15px 20px;
	position: fixed;
	box-sizing: border-box;
}

// invites layer - remaining styles are in _layers.scss
@media (min-width: 1024px) {
	#hidden-invite-layer {
		display: none;
		position: absolute;
		top: 56px;
		right: 16%;
		width: 314px;
		height: 593px;
		background-color: transparent;
		z-index: 5000301; // (PPE-114461) formerly: 2147483647
	}
}

@include breakpoint(2) {
	#hidden-invite-layer {
		display: none;
		position: fixed;
		left: 0;
		top: 50px;
		bottom: 80px;
		width: 100%;
		background-color: transparent;
		z-index: 5000301; // (PPE-114461) formerly: 2147483647
	}
}

// Education network layer - remaining styles are in _layers.scss
#leaveORG {
	z-index: $z-index_layer + 496;
	display: none;
	position: absolute;
	top: 10%;
	left: 50%;
	margin-left: -215px;
	width: 430px;
	padding: 40px 20px;
	background: $white;
	border: 1px solid #fcfcfc;
	box-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
	-webkit-box-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
	-moz-box-shadow: 20 2px 4px rgba(0, 0, 0, 0.5);
	font-family: $font-light;
	font-size: 1.375em;
}