
.header-cme-rclist {
	background: #edf0f5;
	line-height: 44px;
	margin-top: -4px;
	width: 100%;
	color: #222;
	font-family: $font-reg;

	.resp-container {
		display: block;
		position: relative;
		text-align: left;
	}

	.resource-center-items {
	  list-style: none;
	  margin-top: 0;
	  margin-bottom: 0;
	  width: calc(100% - 8rem);
	  white-space: nowrap;
	  overflow: hidden;
	  text-overflow: ellipsis;
	  padding-left: 0;
	  position: relative;
	  display: inline-block;
	}

	a {
		display: inline;
		font-size: .875rem;
		margin-right: .875rem;
		line-height: 2.75;
		color: #222;
		font-family: $font-reg;
	}
	a,
	a:hover,
	a:link,
	a:visited {
		color: inherit;
	}
}


a.cme-submenu-more,
a.cme-submenu-more:visited {
	font-size: .875rem;
	position: absolute;
	right: 0;
	top: 0;
	bottom: 0;
	background: #edf0f5;
	color: #005b80;
	font-family: 'proxima_nova_ltsemibold';
	margin-right: 0;
	text-transform: uppercase;
	color: #222;

	svg {
		line-height: 44px;
		vertical-align: middle;
		margin-bottom: 3px;
		margin-left: 4px;
	}
}
