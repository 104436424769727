
/* DFP Ad tag labels */

#adtagheader {
	margin: 5px 0 0 0;
	padding: 3px 0 10px 0;
	border: none;
	text-align: center;
}

#adtagheader .AdUnit, 
#adtagfooter .AdUnit, 
#right .AdUnit, 
#topRight .AdUnit, 
#rightcol .AdUnit, 
#contentRight .AdUnit,
.has-ad-label .AdUnit-sfp, .AdUnit {
	background-image: url("/pi/global/text/text_advertisement_top.gif");
	background-repeat: no-repeat;
	background-position: bottom center;
	padding-top: 0;
	padding-bottom: 10px;
	text-align: center;

	.de_de & {
		background-image: url("/de/pi/global/text/text_advertisement_top.gif");
	}

	.es_es & {
		background-image: url("/es/pi/global/text/text_advertisement_top_es.png");
    	background-size: 72px auto;
	}

	.fr_fr & {
		background-image: url("/fr/pi/global/text/text_advertisement_top_fr.gif");
	}

	.pt_pt & {
		background-image: url("/pt/pi/global/text/text_advertisement_top_pt.png");
		background-size: 95px auto;
	}
}

#ads-pos-919.AdUnit, #ads-pos-1919.AdUnit {
	background-image: none;
	text-align: left;
	padding-bottom: 5px;
	div:first-child {
		position:absolute;
	}
}

#ads-pos-420.AdUnit,
.has-ad-label.has-ad-str .AdUnit-sfp {
	background-image: none;
	background-repeat: no-repeat;
	background-position: top center;
	padding-top: 0px;
}

#right #ads-pos-122.AdUnit {
	border-top:1px solid #dcdcdc;
	padding-top: 20px;
}


.AdUnit.adlabelifi {
	background-image: url("/pi/global/text/txt-ifi-top.gif") !important;
	background-repeat: no-repeat;
	background-position: bottom center;
	padding-top: 0px;
	padding-bottom: 10px;
	background-size: inherit;
	.de_de & {
		background-image: url("/pi/global/text/txt-ifi-top.gif") !important;
	}

	.es_es & {
		background-image: url("/pi/global/text/txt-ifi-top.gif") !important;
	}

	.fr_fr & {
		background-image: url("/pi/global/text/txt-ifi-top.gif") !important;
	}

	.pt_pt & {
		background-image: url("/pi/global/text/txt-ifi-top.gif") !important;
	}
}


.AdUnit.adlabelifg {
	background-image: url("/pi/global/text/txt-ifg-top.gif") !important;
	background-repeat: no-repeat;
	background-position: bottom center;
	padding-top: 0px;
	padding-bottom: 10px;
	background-size: inherit;
	.de_de & {
		background-image: url("/pi/global/text/txt-ifg-top.gif") !important;
	}

	.es_es & {
		background-image: url("/pi/global/text/txt-ifg-top.gif") !important;
	}

	.fr_fr & {
		background-image: url("/pi/global/text/txt-ifg-top.gif") !important;
	}

	.pt_pt & {
		background-image: url("/pi/global/text/txt-ifg-top.gif") !important;
	}
}

.AdUnit.adlabelblank {
	background-image: none !important;
	background-repeat: no-repeat;
	background-position: bottom center;
	padding-top: 0px;
	padding-bottom: 10px;
}

div[id^='ad-pos-201'].adlabelblank, div[id^='ad-pos-202'].adlabelblank, div[id^='ad-pos-203'].adlabelblank, div[id^='ad-pos-503'].adlabelblank, div[id^='ad-pos-504'].adlabelblank, div[id^='ad-pos-505'].adlabelblank, div[id^='ad-pos-506'].adlabelblank{
	padding-bottom: 0px;
}

/* DFP Ad Positioning
******************************************/

.adcontainer104 {
	text-align: center;
}

.adcontainer104 *, #adtagheader *, #adtagfooter *, .rightAd *, #ads-pos-910 * {
	margin-left: auto;
	margin-right: auto;
}

#ads-pos-910 {
	text-align: center;
}

#ads-pos-520 {
	padding-bottom: 10px;
}
.cc #ads-pos-520{
	background: none;
	@media  (min-width: 768px) and (max-width: 1023px){
		padding: 18px 0px 18px 16px;
		border-radius: 8px;
		box-shadow: 0 0 8px 2px rgba(0, 0, 0, 0.05);
		background-color: #ffffff;
	}
	@media  (min-width: 1024px) and (max-width: 1279px){
		padding: 20px 0px 20px 24px;
		border-radius: 12px;
		box-shadow: 0 0 8px 2px rgba(0, 0, 0, 0.05);
		background-color: #ffffff;
	}
	@media  (min-width: 1280px){
		padding: 18px 0px 18px 24px;
		border-radius: 12px;
		box-shadow: 0 0 8px 2px rgba(0, 0, 0, 0.05);
		background-color: #ffffff;
	}
}
.cc #ads-pos-1520{
	background: none;
	@include breakpoint(1){
		padding: 16px 0px 16px 16px;
		border-radius: 8px;
		box-shadow: 0 0 8px 2px rgba(0, 0, 0, 0.05);
		background-color: #ffffff;
	}
}
.cc #ads-pos-420{
	background: none;
}
.cc #ads-pos-1420{
	background: none;
}

#right #ads-pos-420 {
	float: none !important;
	margin: 0 0 15px;
}

// hide desktop header ad in mobile PPE-357811
@media (min-width: 320px) and (max-width: 767px) {
	#ads-pos-104 {
		display: none;
	}
}


/* Brand alert IFI ads */
#ads-pos-622, #ads-pos-1622, #ads-pos-722, #ads-pos-1722, #featured.section .section-content ul li#ads-pos-622 {
	display: none;
	&.active {
		display: block;
	}
}

#rel-recommend #ads-pos-622 + li + li { 
	display: block;
}
#rel-recommend #ads-pos-622.active + li + li {
	display: none;
}


//fall-back css in case inline style is removed from blank ads on resize
.blank-ad {
	display: none;
}

#dd-hp #ads-pos-1622{
	padding:0px 16px 5px 16px;
}
#column-right #ads-pos-622{
	padding-top: 10px;
}

.page-body section.hp_news ul li#ads-pos-622, .page-body section.hp_news ul li#ads-pos-722, .page-body section.hp_news ul li#ads-pos-1622, .page-body section.hp_news ul li#ads-pos-1722{
	margin-top: 0px;
	padding-bottom: 15px;
	padding-top: 0px;
}

#ads-pos-800, #ads-pos-1800{
	display: none;
}
#ads-pos-904,#ads-pos-906,#ads-pos-907,div[id*='ads-pos-904'],div[id*='ads-pos-906'],div[id*='ads-pos-907']{
	border-bottom:1px solid #d8d8d8;
	background: none !important;
	padding-bottom: 0px !important;
	display: none;
	&.recircwidget{
		display: block;
	}
}

.recircborder{
	border-top:1px solid #d8d8d8;
}
.recircmarginbottom{
    margin-bottom:100px;
	
	.article-column-left & {
		margin-bottom: 60px;
	}
	
	.inf-art-wrap & {
		margin-bottom: 0;
	}
}

#top-pick, *[id*='top-pick'] {
	font-family: proxima_nova_rgbold, sans-serif;
	font-size: 18px;
	font-weight: bold;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.22;
	letter-spacing: normal;
	color: #2f224d;
	margin-bottom:15px;
	margin-top: 30px;
	display:none;
	
	.has-top-picks & {
		display: block;
	}	
}

#ads-pos-421{
	min-height: 45px;
}

#ads-pos-1004,#ads-pos-1420:not(.sticky-video),#ads-pos-1520:not(.sticky-video){
    display: flex;
    box-sizing: content-box;
    align-items: center;
    justify-content: center;
}

div[id^="ads-pos-420_"].AdUnit.adlabelblank,
div[id^="ads-pos-520_"].AdUnit.adlabelblank {
	padding-top: 15px;
	border-top: 1px solid #d8d8d8;
	border-bottom: 1px solid #d8d8d8;
}

#ads-pos-420,#ads-pos-520,div[id^="ads-pos-420_"],div[id^="ads-pos-520_"]{
	text-align: center;
}

div[id^="ads-pos-420"][class*="textAdClass"],
div[id^="ads-pos-520"][class*="textAdClass"] {
	text-align: left;
}

// PROFCORE-24569
#ads-pos-1122, div[id^="ads-pos-1122_"], 
#ads-pos-1420, div[id^="ads-pos-1420_"], 
#ads-pos-1520, div[id^="ads-pos-1520_"], 
#ads-pos-1523, div[id^="ads-pos-1523_"] {

	@include breakpoint(1) {
		margin-top: 22px;
		position: relative;

		.pers-body &, 
		.hp & {
			margin-top: 32px;
		}

		&::before {
			content: 'SCROLL FOR MORE CONTENT';
			font-family: $font-reg;
			font-size: 0.6875rem;
			line-height: 0.6875rem;
			letter-spacing: 0.5px;
			color: #435056;
			text-align: center;
			position: absolute;
			top: -15px;
			left: 0;
			right: 0;

			.de_de & {
				content: 'SCROLLEN FÜR MEHR INHALTE';
			}
		
			.es_es & {
				content: 'DESLIZA PARA MÁS CONTENIDO';
			}
		
			.fr_fr & {
				content: 'PLUS DE CONTENUS CI-DESSOUS';
			}
		
			.pt_pt & {
				content: 'ROLE PARA BAIXO PARA MAIS CONTEÚDOS';
			}
		}
	}
}