// z-index
$z-index_header: 5000000;
$z-index_layer: 6000000;
$z-index_menu: 6000000;

// colors
$mscp-blue: #064aa7;
$hover-blue: #0f428a;
$blue-gray: #6f8590;
$dark-gray: #2a2a2a;
$light-gray: #d8d8d8;
$lighter-gray: #f1f0ee;
$bg-gray: #f7f7f7;
$byline-gray: #767674;
$medium-gray: #979797;
$dark-purple: #2f2148;
$link-purple: #5757a6;
$light-blue: #66adcc;
$lighter-blue: #ecf1f9;
$mid-light-blue: #d6e3f5;
$white: #fff;
$light-black: #222;
$red: #d0021b;
$error-red: #8a0f1e;
$green: #839469;
$teal: #3894a2;
$title-gray: #333132;
$header-gray: #e8e6e6;

// fonts (ordered by weight)
$font-thin: 'proxima_nova_ththin', sans-serif;
$font-thin-it: 'proxima_nova_ththin', sans-serif;
$font-light: 'proxima_nova_ltlight', sans-serif;
$font-light-it: 'proxima_nova_ltlight', sans-serif;
$font-reg: 'proxima_nova_rgregular', sans-serif;
$font-reg-it: 'proxima_nova_rgregular', sans-serif;
$font-semi: 'proxima_nova_ltsemibold', sans-serif;
$font-semi-it: 'proxima_nova_ltsemibold', sans-serif;
$font-bold: 'proxima_nova_rgbold', sans-serif;
$font-bold-it: 'proxima_nova_rgbold', sans-serif;

$font-serif: 'EB Garamond', serif;

$font-rb: 'Roboto Condensed', sans-serif;