.header-tab {
	font-size: 0.875rem;
	letter-spacing: -0.05px;
	margin-right: 1.75rem;
	text-transform: uppercase;
	line-height: 1;
	border-bottom: solid 3px transparent;
	display: inline-block;
	margin-bottom: -2.5px;
	padding: .5rem 0 .375rem;
	font-family: $font-semi;
	color: #000;

	&:last-of-type {
		margin-right: 0;
	}

	&:hover {
		border-bottom: solid 3px $mscp-blue;
		text-decoration: none;
	}

	&.is-current {
		border-bottom: solid 3px $hover-blue;
	}

	@include breakpoint(2) {
		font-size: 0.6875rem;
		margin-right: 1.125rem;
	}
}

a.header-tab {
	&:link,
	&:visited,
	&:hover {
		color: #000;
		text-decoration: none;
	}
}

.new-label {
	font-size: 9px;
	font-weight: bold;
	color: #d0021c;
	vertical-align: top;
	text-transform: uppercase;
	font-family: "proxima_nova_ltsemibold", arial, sans-serif;
	text-decoration: none;
	display: inline-block;
}

.badge-label {
	margin-left: 1px;
	background: url("/pi/global/icons/icon-star-badge.svg") no-repeat;
	background-size: 12px auto;
	width: 12px;
	height: 14px;
	font-size: 0;
	vertical-align: top;
	display: inline-block;
}

.callout-label {
	margin: 0 2px 0 0;
	font-family: $font-rb;
	color: $red;
	font-size: 0.875rem;
	line-height: 1.063rem;
	letter-spacing: -0.3px;
	font-weight: 600;
	text-transform: uppercase;
	display: inline-block;

	@include breakpoint(2) {
		font-size: 0.6875rem;
	}

	.hp_resource-center ul li:hover a &, 
	.hp_specialty ul li:hover a & {
		color: $white;
	}
}