// PROFCORE-23619 + PROFCORE-23916

#ads-pos-904, div[id^="ads-pos-904_"],
#ads-pos-906, div[id^="ads-pos-906_"],
#ads-pos-907, div[id^="ads-pos-907_"],
#ads-pos-916, div[id^="ads-pos-916_"],
#ads-pos-917, div[id^="ads-pos-917_"] {
	min-height: 60px;
}

#ads-pos-1004, div[id^="ads-pos-1004_"] {
	min-height: 70px;
}

#ads-pos-101, div[id^="ads-pos-101_"],
#ads-pos-104, div[id^="ads-pos-104_"],
#ads-pos-141, div[id^="ads-pos-141_"] {
	min-height: 100px;
}

#ads-pos-420, div[id^="ads-pos-420_"],
#ads-pos-520, div[id^="ads-pos-520_"] {
	min-height: 120px;
}

#ads-pos-1420, div[id^="ads-pos-1420_"],
#ads-pos-1520, div[id^="ads-pos-1520_"] {
	min-height: 150px;

	@include breakpoint(1) {
		min-height: 180px;
	}
}

#ads-pos-122, div[id^="ads-pos-122_"],
#ads-pos-910, div[id^="ads-pos-910_"] {
	min-height: 250px;
}

#ads-pos-1122, div[id^="ads-pos-1122_"] {
	min-height: 250px;

	@include breakpoint(1) {
		min-height: 280px;
	}
}