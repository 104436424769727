
.resp-container {
	max-width: 96%;
	width: 1240px;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: center;
	    -ms-flex-pack: center;
	        justify-content: center;
	margin-left: auto;
	margin-right: auto;
	@media (max-width: 767px){
		max-width:90%;
	}
}

.page-header {
	border-top: solid 4px #a2b0ba;
	padding-top: .75rem;
	box-shadow: 0 1px 2px rgba(0, 0, 0, .1);
	margin-bottom: 5px;
	position: relative;
	@media (max-width: 767px){
		border-top: solid 2px #a2b0ba;
		padding-top: .375rem;
		padding-bottom: .375rem;
	}

	&.simple {
		border-top: solid 2px #a2b0ba;
	}
}

.header-row-top {
	-webkit-box-pack: justify;
	    -ms-flex-pack: justify;
	        justify-content: space-between;
}


.header-row-bottom {
	margin-top: .5rem;
	@media (max-width: 767px){display: none;}
}

.header-specialty-menu {
	display: none;
}

.user-menu {
	display: none;
}



.header-edition {
	@media (max-width: 1023px){display: none;}
}

.right-group {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
}
