.user-links {
	@media (max-width: 1023px){
		display: none;
	}
	display: inline-block;
	position: relative;
	font-size: .875rem;
	line-height: 1.125rem;
	font-family: $font-reg;
	align-self: center;

	a {
		margin-left: 1.375rem;
		text-decoration: none;
	}

	a:hover {
		text-decoration: none;
		color: #007cb1;
	}

	svg {
		margin-left: 6px;
		vertical-align: bottom;
		width: 1.125rem;
		height: 1.125rem;
		display: inline-block;
	}
}

.header-invitations-link {
	display: inline-block;
	vertical-align: bottom;
	position: relative;

	&:after {
		content: '';
		background: #d0021b;
		border-radius: 50%;
		width: 6px;
		height: 6px;
		position: absolute;
		top: 1px;
		right: -9px;
	}
}

.user-links-settings {
	display: inline-block;

	svg {
		color: $mscp-blue;
	}
}

.user-popup {
	padding: 20px 0 0 0;
	position: absolute;
	right: 0;
	z-index: $z-index_header + 200;
	width: 150px;
	float: right;
}

.user-popup-menu {
	padding: 15px 0 17px;
	background: #fff;
	border: 1px solid #e3e3e3;
	text-align: left;
	-webkit-box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
	-moz-box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
	box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
	-webkit-border-radius: 8px;
	-moz-border-radius: 8px;
	border-radius: 8px;
	position: absolute;
    top: 12px;
    right: -24px;
	width: 260px;
	// z-index: $z-index_header + 2000;
	z-index: 5002000;
	display: none;

	&.is-expanded {
		display: block;
	}

	ul {
		margin: 0;
		padding: 0;

		li {
			padding: 0;
		}
	}

	a.user-popup-menu-link {
		margin: 0;
		padding: 15px 30px;
		color: $light-black !important;
		display: block;
		font-size: 1rem;
		line-height: normal;
		box-sizing: border-box;
		width: 260px;

		&:hover {
			background: #eaf1f9;
			@include hover-ease;
		}
		
		&.inactive {
			display: none;
		}
	}

	&::after,
	&::before {
		bottom: 100%;
		left: 88%;
		border: solid transparent;
		content: " ";
		height: 0;
		width: 0;
		position: absolute;
		pointer-events: none;
	}

	&::after {
		border-color: rgba(255, 255, 255, 0);
		border-bottom-color: $white;
		border-width: 10px;
		margin-left: -10px;
	}

	&::before {
		border-color: rgba(221, 221, 221, 0);
		border-bottom-color: #ddd;
		border-width: 11px;
		margin-left: -11px;
	}
}
