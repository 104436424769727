// hide ad units that are not part of the current user viewport (mobile or desktop)
// hide desktop header ad in mobile PPE-357811
@media (min-width: 320px) and (max-width: 767px) {
    body {
        #ads-pos-104 {
            display: none;
        }
    }
}
// remove duplicate header ads on desktop home pages, includes .mobile_adlabelleft #ads-pos-1004,
@media (min-width: 768px) {
    body {
        .mobile_adlabelleft {
            display: none;
        }
    }
}


