.sp-arrow {
	transition: all .2s;
	position: absolute;
	right: 20px;
	top: 20px;

	&:before {
	  content: '';
	  position: absolute;
	  top: -6px;
	  left: -12px;
	  border-top: 12px solid #064aa7;
	  border-left: 12px solid transparent;
	  border-right: 12px solid transparent;
	}

	&:after {
	  content: '';
	  position: absolute;
	  top: -6px;
	  left: -11px;
	  border-top: 11px solid #fff;
	  border-left: 11px solid transparent;
	  border-right: 11px solid transparent;
	}
}

.no-subheader .sp-arrow {
	top: 27px;
}

@media (max-width: 1023px){
	.mobile-nav-toggle:checked ~ .slide-out-menu {
		display: block;
	}
}

.slide-out-menu {
	text-align: left;
	transition: left .2s;
	box-sizing: border-box;
	position: absolute;
	top: 83px;
	height: 92vh;
	left: 0;
	width: 24rem;
	max-width: 100%;
	background: #fff;
	z-index: $z-index_menu + 504;
	display: none;
	border-right: 1px solid rgba(0, 0, 0, .11);
	box-shadow: 0 8px 20px rgba(0, 0, 0, .3);
	overflow-y: scroll;
	padding: .75rem;
	padding-top: 0;
	padding-bottom: 200px;
	font-family: $font-reg;
	-webkit-overflow-scrolling: touch;

	@media(max-width: 767px){
		top: 47px;
		width: 16rem;
	}

	&--tabs {
		margin: 0;
		padding: 0;
		font-family: $font-semi;
		border-bottom: solid 2px #4a4a4a;
		text-transform: uppercase;

		@media (min-width: 767px) and (max-width: 1023px) {
			display: none;
		}
	}

	&--group {
		margin: 0 0 4px;
		border-bottom: solid 2px #4a4a4a;
	}
}


.mobile-menu-item {
	display: block;
	position: relative;
    border-bottom: 1px solid #edf0f5;
    padding: 0.9375rem 0 0.8125rem;
    line-height: normal;
    font-size: 0.9375rem;
    text-decoration: none;
    color: $title-gray;

	a,
	a:link,
	a:hover,
	a:visited,
	a:active {
		color: $title-gray;
		text-decoration: none;
		display: inline-block;
		width: 100%;
		position: relative;

		&.is-current {
			border-bottom: solid 3px #005b80;
		    padding-bottom: .9rem;
		}

		&.header-tab--for-you {
			display: none;
		}
	}

	a .mobile-menu-item-subhead,
	a:link .mobile-menu-item-subhead,
	a:hover .mobile-menu-item-subhead,
	a:visited .mobile-menu-item-subhead,
	a:active .mobile-menu-item-subhead {
		display: block;

		&.is-current {
			border-bottom: solid 3px #005b80;
		    padding-bottom: .9rem;
		}
	}

	.new-label{
	  font-size: 9px;
	  line-height: 9px;
	  font-weight: bold;
	  color: #d0021c;
	  vertical-align: top;
	  text-transform: uppercase;
	  text-decoration: none;
	  font-family: "proxima_nova_ltsemibold", arial, sans-serif;
	}

	&.user-invites-link {

		a {

			&:after {
				content: '';
				background: #d0021b;
				border-radius: 50%;
				width: 6px;
				height: 6px;
				position: absolute;
				top: 1px;
				left: 70px;
			}
		}
	}
}

.mobile-menu-item.login-link,
.mobile-menu-item.register-link {
		font-family: $font-semi;
	}

a.mobile-menu-button:link,
a.mobile-menu-button:hover,
a.mobile-menu-button:visited,
a.mobile-menu-button:active{
	background: #064aa7;
    line-height: 2.25rem;
    display: inline-block;
    padding: 0 1.25rem;
    font-size: 1rem;
    border-radius: 3px;
    color: #fff;
    margin-top: 1rem;
	margin-right: .5rem;
	font-family: $font-thin;
}

.mobile-menu-item a.header-tab--for-you,
.mobile-menu-item a:active.header-tab--for-you,
.mobile-menu-item a:hover.header-tab--for-you,
.mobile-menu-item a:link.header-tab--for-you,
.mobile-menu-item a:visited.header-tab--for-you {
    display: none;
}

.mobile-specialty-menu,
.mobile-edition-menu {
	transition: all .5s;
	max-height: 0;
	overflow: hidden;
	margin-bottom: 0;

	&.is-expanded {
		padding-top: .25rem;
		max-height: 1000px;
		margin-bottom: -1rem;
	}
}

.mobile-edition-menu {

	.mobile-menu-item {
		margin-left: 16px;

		&.affiliate-type {
			margin-left: 0;
		}

		&:last-of-type {
			margin-bottom: 35px;
		}

		a.is-current {
			padding-bottom: 0;
			border-bottom: none;
			font-family: $font-bold;
		}
	}
}

.mobile-specialty-menu.is-expanded {
	max-height: 4000px;
	margin-bottom: -1rem;
}

.mobile-menu-item-subhead {
	font-family: $font-semi;
	text-transform: uppercase;
	display: block;
	&.user {
		text-transform: none;
	}
}

.is-rotated {
	transform: rotate(-180deg);
	transition: transform 150ms ease;
}

.page-header.simple .slide-out-menu {
	top: 43px;
}
body.menuView {
	overflow: hidden;
	width: 100%;
}
