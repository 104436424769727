
/* Adhesive footer styles */

$mid-gray: #767674;
$maxHeight: 130px;
@mixin sharedClosedProps {
  right: 0;
  top: 5px;
  cursor: pointer;
  z-index: 10;
  padding: 0 10px;
}

@mixin mobileCloseText {
  top: -2px;
  right: 1%;
}


/* padding for legal footer */
.use-adhesive-footer .footer_legal-text {
  margin-top: 1rem;
  padding-bottom: 4rem;
}

.page-footer {
  padding-bottom: 6rem;
}

@media (max-width: 767px) {
  .adhesive-footer-wrapper {
    display: block;
    max-height: 100px;
  }
}
/*
  after template is updated to remove old sticky footer, remove unused css from /www
  https://img.staging.medscapestatic.com/medscape-core/www/css/article/article/components/_ads.scss
*/
.adhesive-footer-wrapper, .sticky-footer-ad {
  background-color: #f0efef;
  position: fixed;
  justify-content: center;
  align-items: center;
  bottom: 0;
  left: 0;
  width: 100%;
  margin: 0 auto;
  z-index: 1999990;
  overflow: hidden;
  display: none;
  box-shadow: 0px -3px 6px 0px #7676744D;
}

.adhesive-footer_content, .innerAdContainer {
  position: relative;
  display: inherit;
  justify-content: center;
  margin: initial;
}

/* remove overwritting styles in reference after deployment to prod */
.sticky-footer-ad {
  background: initial;
}

.footer-close-btn {
  width: 15px;
  height: 15px;
  position: absolute;
  background-image: url('//img.medscapestatic.com/pi/cme/icons/close-button-blk-2x.png');
  background-repeat: no-repeat;
  background-size: contain;
  @include sharedClosedProps;
}

.footer-close-btn_white {
  @extend .footer-close-btn;
  background-image: url('//img.medscapestatic.com/pi/cme/icons/close-button-wht-2x.png');
}

.footer-close-btn-text {
  @include sharedClosedProps;
  color: $mid-gray;
  text-transform: uppercase;
  top: -4px;
  position: absolute;
}


@media (max-width: 767px) {
  .sticky-footer-ad {
    max-height: 120px;
  }
  #stick-ad-outter-wrapper.sticky-footer-ad {
    bottom: 0;
    height: initial;
  }

  .adhesive-footer_content {
    width: 100%;
    height: auto;
    min-height: 100px;
    max-height: 100px;
    color: $mid-gray;
    display: block;

	.adlabelblank {
		margin: 0 25px;

		iframe {
			width: 100%;
		}
	}
  }
}


#ads-af-pos-145,#ads-pos-145 {
  background-position: 18vw 15px;
  padding: 0;
  position: relative;
  width: 100vw;
}


#ads-af-pos-145{
	div {
		iframe {
			min-width: 728px;
			height: auto;
            padding: 25px 0 3px;
	    }
    }
}

@media (min-width: 767px){
  .adhesive-footer_content{
    width: 1250px;
  }
}

.globalpromoadsunit.interscroller {
  background: #fff;
  display: flex !important;
  flex-direction: column;
  height: 400px!important;
  justify-content: space-between;
  left: calc(-50vw + 50%)!important;
  margin: 40px 0;
  min-height: 400px;
  padding-top: 0px;
  padding-bottom: 0px;
  position: relative!important;
  width: 100vw !important;
  z-index: 10;
  
  > div {
    clip-path: polygon(0 0, 0 100%, 100% 100%, 100% 0) !important;
    -webkit-clip-path: polygon(0 0, 0 100%, 100% 100%, 100% 0) !important;
    left: 0!important;
    position: absolute!important;
    right: 0!important;
    text-align: initial!important;
    top: 0!important;
    height: 100% !important;

    >iframe {
			-webkit-backface-visibility: hidden;
      backface-visibility: hidden;
      border: 0px;
      position: fixed!important;
			left: 0;
      min-height: 100%;
      top: 0!important;
      vertical-align: bottom;
			width: 100%;
		}
  }

  &:before, &:after {
    align-items: center;
    background-color: #F2F4F5;
    color: #000;
    display: flex;
    font-size: 10px;
    height: 10px;
    justify-content: center;
    line-height: 12px;
    position: relative;
    width: 100%;
    z-index: 1;
  }

  &:before {
    content: 'INFORMATION FROM INDUSTRY BELOW';
    top: -10px;
  }
		
	&:after {
    content: 'END OF INFORMATION FROM INDUSTRY';
    top: 10px;
  }
}
