.header-logo {
	/* height: 2.5rem; */
	height: 2.875rem;
	
	@media (max-width: 1023px){
		position: absolute;
		top: .25rem;
		padding: .75rem -0px;
		left: 50%;
		transform: translateX(-50%);
		// height: 1.875rem;
		height: 1.75rem;
	}
	@media (max-width: 767px){
		top: .0;
		left: 50%;
		transform: translateX(-50%);
		// height: 1.375rem;
		height: 1.625rem;
	}

	img {
		// height: 2.5rem;
		height: 2.875rem;

		@media (max-width: 1023px) {
			// height: 1.875rem;
			height: 1.75rem;
		}

		@media (max-width: 767px) {
			// height: 1.375rem;
			height: 1.625rem;
		}
	}
}


.simple .header-logo {
	height: 2.25rem;
}


.dropdown-view .header-logo{
   z-index: $z-index_header + 300;
}
