.header-search {
	margin-left: 1.375rem;
	border: solid 1px #ccd6dd;
	border-radius: 3px;
	padding: 2px;

	@media (max-width: 767px){
		display: none;
	}
	font-family: $font-reg;

	form {
		margin-bottom: 0;
	}
}
@media (min-width: 768px) and (max-width: 1279px) {
.header-search:not(.is-expanded) {
	.search-input-text{
		border-right: none;
		padding: 0;
		padding-left: 0;
	}
  .search-open-button{
		display: inline-block;
	}
  .search-submit-button {
    display: none;
  }
}
}

// Expanded search medium screens
@media (min-width: 768px) and (max-width: 1279px) {
.header-search.is-expanded{
	.search-input-text {
		max-width: 400px;
		display: inline-block;
	}
	.search-submit-button {
		display: inline-block;
	}
	.search-open-button{
		display: none;
	}
}
}

// Expanded search medium screens
@media (max-width: 767px) {
.header-search.is-expanded{
	position: absolute;
	width: 100%;
	background: #fff;
}
}






.ilfulllist,
.user-menu {
    display: none; }

.search-input-text::-webkit-input-placeholder {
    text-transform: uppercase;
	font-family: $font-reg;
    font-size: 15px;
    color: #4a4a4a; }
.search-input-text::-moz-placeholder {
    text-transform: uppercase;
	font-family: $font-reg;
    font-size: 15px;
    color: #4a4a4a; }
.search-input-text:-ms-input-placeholder {
    text-transform: uppercase;
	font-family: $font-reg;
    font-size: 15px;
    color: #4a4a4a; }
.search-input-text::placeholder {
    text-transform: uppercase;
	font-family: $font-reg;
    font-size: 15px;
    color: #4a4a4a; }


.mobile-search-button {
	border: none;
    margin-left: 1.25rem;
    border: solid 1px #ccd6dd;
    border-radius: 3px;
    padding: 2px 6px;
    height: 1.75rem;
    display: flex;
    align-items: center;
	svg {
		height: 20px;
		width: 20px;
		fill: #005b80;
	}
	@media (max-width: 1279px) {
		display: none;
	}

	display: none;
	@media (max-width: 767px) {
		display: flex;
	}

	.close {
		display: none;
	}
}
@media (max-width: 767px) {
  .modal-open .mobile-search-button {
    display: none;
  }
}
.search-input-text{
	transition: max-width .2s;
	border: none;
	border-right: solid 1px #ccd6dd;
	line-height: 1rem;
	padding: .35rem;
	padding-left: 1rem;
	vertical-align: top;
	font-size: 15px;
	width: 9rem;
	box-sizing: border-box;
	font-family: $font-reg, arial, sans-serif;
	@media (max-width: 1279px) {
		max-width: 0;
		margin-right: -4px;
	}
}
.search-input-text[placeholder]:focus::-webkit-input-placeholder {
	transition: opacity 0.3s ease;
	opacity: 0;
}
.search-input-text[placeholder]:focus:-moz-placeholder {
	color: transparent;
}
.search-input-text[placeholder]:focus::-moz-placeholder {
	color: transparent;
}
.search-input-text[placeholder]:focus:-ms-input-placeholder {
	color: transparent;
}
.search-submit-button {
	padding: 0 .875rem;
	height: 1.75rem;
	border: none;
	background: none;
	svg {
		height: 20px;
		width: 20px;
		fill: #005b80;
	}
	@media (max-width: 767px) {
		display: none;
	}
}
.search-open-button{
	padding: 0 .875rem;
	height: 1.75rem;
	border: none;
	background: none;
	svg {
		height: 20px;
		width: 20px;
		fill: #005b80;
	}
	@media (min-width: 768px) {
		display: none;
	}
}


#searchLayer .searchContainer {
	text-align: center;
    padding: 0;
    padding-top: 1rem;
    background: none;
}

.searchContainer #search-form-header,
.searchContainer .search-form-header {

	display: -webkit-inline-box;

	display: -ms-inline-flexbox;

	display: inline-flex;
    width: auto;

    width: 17rem;
    background: #fff;
    border: solid 1px #ddd;
    border-radius: 4px;
	display: -moz-inline-flex;
	display: -ms-inline-flex;
	display: -o-inline-flex;
	display: inline-flex;
}

.searchContainer .search-input-text {
	-webkit-box-flex: 1;
	    -ms-flex: 1;
	        flex: 1;
}



#medscapeLogo a,
#headersearchbtn a,
#searchlayer .closeLayer a {
  width: 100%;
  height: 100%;
  display: block;
  text-decoration:none;
}


/*#medscapeheadercontainer #searchlayer .searchContainer {
	display:none;
}*/

#searchlayer {
  padding: 45px 0 0;
background: transparent;
  height: 45px;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: $z-index_menu + 504;
  display: none;
  /*-webkit-transition: all .2s ease-in-out;*/
}

#searchlayer.showSearch {
  /*position: absolute;*/
  display: table;
}
.showSearch .layer-whiteout {
    visibility: visible;
    opacity: .8;
    height: 800px;
  }
#medscapeheadercontainer #searchlayer.showSearch .searchContainer {
  display: table-cell;
}

#searchlayer .closeLayer {
  background: #fff;
  background-size: 24px 24px;
  width: 38px;
  height:38px;
  display: table-cell;
  vertical-align: middle;
  position:absolute;
  top: 5px;
  right: 16px;
}
#searchlayer .closeLayer:after, #searchlayer .closeLayer:before {
	background:#aeaeae;
	width:76%;
	height:2px;
}
#searchlayer .searchContainer {
  width: 100%;
  /*display: table-cell;*/
  vertical-align: middle;
  padding:0;
  padding-top: 1rem;
  z-index: $z-index_menu + 505;
  position:relative;
	text-align: center;
}

#searchLayer .searchContainer {
	text-align: center;
    padding: 0;
    padding-top: 1rem;
    background: none;
}

.searchContainer {
	#search-form-header,
	.search-form-header {
		display: inline-flex;
	    width: auto;
	    width: 17rem;
	    background: #fff;
	    border: solid 1px #ddd;
	    border-radius: 4px;

		display: -webkit-inline-flex;
		display: -moz-inline-flex;
		display: -ms-inline-flex;
		display: -o-inline-flex;
		display: inline-flex;
	}

	.search-input-text {
		flex: 1;
		max-width: 400px;
	}
	.search-submit-button {
		display: block;
	}
}

#searchlayer .closeLayer:after, #searchlayer .closeLayer:before {
	background:#aeaeae;
	width:76%;
	height:2px;
}


.btn-close {
   position:absolute;
   top:14px;
   right:10px;
   width:30px;
   height:30px;
}

.btn-close:after,  .btn-close:before {
   content: '';
   position: absolute;
   height: 1px;
   width: 100%;
   top: 50%;
   left: 0;
   margin-top: -1px;
   background: #000;
}
.btn-close:before{
   -ms-transform: rotate(45deg);
   -webkit-transform:rotate(45deg);
   transform: rotate(45deg);
}
.btn-close:after{
   -ms-transform: rotate(-45deg);
   -webkit-transform:rotate(-45deg);
   transform: rotate(-45deg);
}

.btn-close span {
   display:none;
}
.btn-close.btn-close-grey:after,  .btn-close.btn-close-grey:before {
   background: #aaa;
}
.btn-close-white:after, .btn-close-white:before {
   background:#fff;
}



// autofill search styles
.ilfulllist,
.user-menu {
  display: none;
}

/*Instant Look UP*/

.ilfulllist, #ilfulllist {
  text-align: left;
  clear: both;
  display: none;
  border-left: 1px solid #ddd;
  border-right: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
  background-color: #fff;
  position: absolute;
  z-index: $z-index_header + 500;
  -webkit-border-radius: 0 0 5px 5px;
  -moz-border-radius: 0 0 5px 5px;
  border-radius: 0 0 5px 5px;
  font-family: 'proxima_nova_rgregular', Arial, Helvetica, sans-serif;
  font-size: 12px;
  word-wrap: break-word;
  @media (max-width: 767px) {
	margin-top:30px;
	width: 225px;
  }
  @media (min-width: 768px) {
	width: 198px;
    margin: 3px 0 0 -3px;
  }
}

.il_combo_nor {
  display: none;
  margin: 0;
  padding: 8px;
}

#search_hint {
  float: right;
  margin: -7px 0 0 0;
  font-size: .9em;
  color: #666;
  text-shadow: 0 1px 0 #fff;
}

.ilfulllist ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
.ilfulllist ul li:hover {
	background-color:#f1f1f1;
}
.ilfulllist ul li.focused {
	background-color:#f1f1f1;
}
.ilfulllist ul li a:focus {
	background-color:#f1f1f1;
	outline: 0;
}
.ilfulllist ul li a {
  display: block;
  word-wrap: break-word;
  outline: 0;
}
.ilfulllist ul li a:link {
  color: #464646;
  text-decoration: none;
  display: block;
  min-height: 21px;
  padding-top: 5px;
  padding-left: 5px;
  padding-right: 28px;
  outline: 0;
}

.ilfulllist ul li a:visited {
  color: #464646;
  text-decoration: none;
  display: block;
  min-height: 21px;
  padding-top: 5px;
  padding-left: 5px;
  padding-right: 28px;
  outline: 0;
}

.ilfulllist .defaultiltxt {
  padding-top: 8px;
  padding-bottom: 8px;
  background: none;
  width: auto;
}

.ilfulllist ul li a.il_drug_result {
  background-image: url(/pi/reference/ornaments/bg-il-icons-sprite.png);
  background-repeat: no-repeat;
  background-size: 23px 299px;
  background-position: right 1px;
}

.ilfulllist ul li a.il_cond_result {
  background-image: url(/pi/reference/ornaments/bg-il-icons-sprite.png);
  background-size: 23px 299px;
  background-repeat: no-repeat;
  background-position: right -45px;
}

.ilfulllist ul li a.il_pro_result {
  background-image: url(/pi/reference/ornaments/bg-il-icons-sprite.png);
  background-size: 23px 299px;
  background-repeat: no-repeat;
  background-position: right -90px;
}

.ilfulllist ul li a.il_anat_result {
  background-image: url(/pi/reference/ornaments/bg-il-icons-sprite.png);
  background-size: 23px 299px;
  background-repeat: no-repeat;
  background-position: right -137px;
}

.ilfulllist ul li a.il_labm_result {
  background-image: url(/pi/reference/ornaments/bg-il-icons-sprite.png);
  background-size: 23px 299px;
  background-repeat: no-repeat;
  background-position: right -183px;
}

.ilfulllist ul li a.il_medd_result {
  background-image: url(/pi/reference/ornaments/bg-il-icons-sprite.png);
  background-size: 23px 299px;
  background-repeat: no-repeat;
  background-position: right -228px;
}

.ilfulllist ul li a.il_calc_result {
  background-image: url(/pi/reference/ornaments/bg-il-icons-sprite.png);
  background-size: 23px 299px;
  background-repeat: no-repeat;
  background-position: right -274px;
}

@media only screen and (-webkit-min-device-pixel-ratio: 2), only screen and (min-resolution: 192dpi) {
  .ilfulllist ul li a.il_drug_result {
    background-image: url(/pi/reference/ornaments/bg-il-icons-sprite@2x.png);
    background-repeat: no-repeat;
    background-size: 23px 299px;
    background-position: right 1px;
  }
  .ilfulllist ul li a.il_cond_result {
    background-image: url(/pi/reference/ornaments/bg-il-icons-sprite@2x.png);
    background-size: 23px 299px;
    background-repeat: no-repeat;
    background-position: right -45px;
  }
  .ilfulllist ul li a.il_pro_result {
    background-image: url(/pi/reference/ornaments/bg-il-icons-sprite@2x.png);
    background-size: 23px 299px;
    background-repeat: no-repeat;
    background-position: right -90px;
  }
  .ilfulllist ul li a.il_anat_result {
    background-image: url(/pi/reference/ornaments/bg-il-icons-sprite@2x.png);
    background-size: 23px 299px;
    background-repeat: no-repeat;
    background-position: right -137px;
  }
  .ilfulllist ul li a.il_labm_result {
    background-image: url(/pi/reference/ornaments/bg-il-icons-sprite@2x.png);
    background-size: 23px 299px;
    background-repeat: no-repeat;
    background-position: right -183px;
  }
  .ilfulllist ul li a.il_medd_result {
    background-image: url(/pi/reference/ornaments/bg-il-icons-sprite@2x.png);
    background-size: 23px 299px;
    background-repeat: no-repeat;
    background-position: right -228px;
  }
  .ilfulllist ul li a.il_calc_result {
    background-image: url(/pi/reference/ornaments/bg-il-icons-sprite@2x.png);
    background-size: 23px 299px;
    background-repeat: no-repeat;
    background-position: right -274px;
  }
}

.ilfulllist ul li a:link {
  color: #464646;
  text-decoration: none;
  display: block;
  min-height: 21px;
  padding-top: 5px;
  padding-left: 5px;
  padding-right: 28px;
}

.ilfulllist ul li a:visited {
  color: #464646;
  text-decoration: none;
  display: block;
  min-height: 21px;
  padding-top: 5px;
  padding-left: 5px;
  padding-right: 28px;
}

#drug_rtlinks {
  font-size: .9em;
  font-weight: bold;
  padding: 10px 3px;
  margin: 10px 0;
  border: 1px solid #afbcae;
  background: #f3f6f2;
  -moz-border-radius: 4px;
  -webkit-border-radius: 4px;
  border-radius: 4px;
  text-align: center;
  border-color: #afbcae #e2e8e1 #afbcae #e2e8e1;
}

.ilfulllist .defaultiltxt {
  padding-top: 8px;
  padding-bottom: 8px;
  background: none;
  width: auto;
}

.ilfulllist .defaultiltxt td b {
  font-size: 1.20em;
  color: #345B0E;
}
