//	File: _mixins.scss | Author: rmatyas | Modified: 12/13/2017

// breakpoints
@mixin breakpoint($breakpoint) {
	@if $breakpoint == 1 {
     	@media (max-width:767px)  { @content; }
  	}

  	@else if $breakpoint == 2 {
     	@media (max-width: 1023px) { @content; }
  	}

  	@else if $breakpoint == 3 {
     	@media (max-width: 1279px) { @content; }
  	}

  	@else if $breakpoint == 4 {
     	@media (min-width: 1280px) { @content; }
  	}
}

// smooth hover transition
@mixin hover-ease {
	-webkit-transition: all 350ms ease;
	-moz-transition: all 350ms ease;
	-ms-transition: all 350ms ease;
	transition: all 350ms ease;
}

// no hover for touch devices
@mixin no-hover {
	@media (hover:none), (hover:on-demand) {
    	@content;
	}
}