// Decision Point header

@import '../vars';
@import '../mixins';
.dp-body {

	&:not(.decision-hub) .page-header {
		margin-bottom: 0;
		padding-top: 0;
		border-top: none;
		background: #090b20 url("/pi/global/header/decision-point/header-odp-thin.jpg") no-repeat center top;
		background-size: 1700px auto;
		-webkit-box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.2);
		-moz-box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.2);
		box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.2);
		z-index: 1;

		a,
		a:link,
		a:visited {
			color: $white;
		}

		@include breakpoint(1) {
			padding-bottom: inherit;
			background-position: 55% 55%;
			background: none;
			box-shadow: none;
			background: #090b20 url("/pi/global/header/decision-point/header-odp-thin.jpg") no-repeat center top;
		
		}
	}

	.dp-header {
		margin-left: auto;
		margin-right: auto;
		padding: 15px 20px;
		max-width: 1240px;
		width: auto;
		display: -ms-flexbox;
		display: flex;
		-webkit-justify-content: space-between;
		-ms-flex-pack: justify;
		justify-content: space-between;
		color: $white;

		@include breakpoint(1) {
			padding: 18px 14px 18px 0;
			text-align: center;
			-webkit-justify-content: flex-start;
			-ms-flex-pack: start;
			justify-content: flex-start;
		}
		.right-group {
			a.mobile-search-button{
				display: none;
			}
		}
		.slide-out-menu {
			padding-bottom: 182px;
			.mobile-menu-item {
				border-bottom: none;
				padding: 15px 0 0 0;
				button {
					border: none;
					background-color: transparent;
				}
				button:focus {
					outline: none;
				}
				img.decision-combined-shape {
					position: absolute;
					top: 27px;
					z-index: 1;
					left: 10px;
					height: 20px;
				}
				input#search-input-mobile {
					border-radius: 5px;
					border: solid 1px #e9e9e9;
					background-color: #ffffff;
					min-width: 192px;					
					padding-left: 38px;
					height: 40px;
					font-family: proxima_nova_rgregular,sans-serif;
					color: #767674;
					box-sizing: content-box;
				}
				input#search-input-mobile :focus {
					outline: none;
				}
				input:focus{
					outline: none;
				}
				.mobile-menu-item-subhead.user {
					font-family: proxima_nova_ltsemibold,sans-serif; 
					font-weight: 600;
				}
				a {
					span {
						margin-left: 8px;
					}
				}
			}
			.mobile-menu-button {
				margin-left: 8px;
			}
		}
	}

	.dp-title-block {
		//background-image: url(/pi/sites/odp/medscape-oncology-dp-logo-new@3x.png);
		background-repeat: no-repeat;
		background-size: 455px 27px;
		cursor: pointer;
		margin-top: auto;
		margin-bottom: auto;
		@include breakpoint(1) {
			width: 221px;
			//background-image: url("/pi/sites/odp/logo/odp-logo@3x.png");
			background-size: 221px 38px;
			background-position:center;
			background-repeat: no-repeat;
			flex: 1 1 auto;
			order: 2;
		}
		@include breakpoint(4) {
			display: flex;
		}
		@include breakpoint(3) {
			display: block;
		}
		@include breakpoint(1) {
			display: flex;
			align-items: center;
			flex-direction: column;
		}
		>img {
			margin-right: .5rem;
			@include breakpoint(4) {
				width: 122px;
				height: 1.6875rem;
				display: block;
			}
			@include breakpoint(3) {
				width: auto;
				height: 1.6875rem;
				display: block;
			}
			@include breakpoint(2) {
				height: 1.6875rem;
			}
			@include breakpoint(1) {
				height: 1.3125rem;
			}
		}
		.dp-title {
			display: block;
			font-size: 1.375rem;
			text-transform: uppercase;
			font-weight: 600;
			@include breakpoint(1) {
				font-size: 14px;
			}
			@include breakpoint(2) {
				font-size: 1rem;
			}
			@include breakpoint(3) {
				width: 100%;
				display: block;
			}
			@include breakpoint(4) {
				width: auto;
				display: block;
			}
		}
	}

	&:not(.decision-hub) .right-group {
		margin-left: 20px;
	}

	&:not(.decision-hub) .user-links {
		font-size: 0.9375rem;
		white-space: nowrap;

		@include breakpoint(2) {
			display: flex;
		}

		@include breakpoint(1) {
			display: none;
			&.active {
				display: block;
			}
		}

		a {

			&:hover,
			&:hover svg {
				color: #a1ebff;
			}		
		}

		.user-links-settings {
			display: inline-block;

			svg {
				color: $white;
			}
		}

		&#loggedout_user_links {

			a {
				margin: 0;

				&:last-child {
					margin-left: 8px;

					&::before {
						margin-right: 8px;
						content: "\007C";
						font-family: $font-light;
						font-size: 1.5rem;
						vertical-align: sub;
					}
				}
			}
		}
	}

	.odp-header-search {
		width: 240px;
		height: 40px;
		border-radius: 6px;
		background-color: #ffffff;

		#search-form-header,
		.search-form-header {
			height: 100%;
			display: flex;		
			.search-button {
				background: none;
				border: none;
				img {
					margin-left: 4px;
				}
			}
			.search-button:focus {
				border: none;
				outline: none;
			}
			#search-input {
				transition: max-width .2s;
				border: none;
				padding: .35rem;
				vertical-align: bottom;
				font-size: 15px;
			}
			#search-input:focus {
				border: none;
				outline: none;
			}
		}
	}
	&:not(.decision-hub) .user-popup-menu {
		white-space: normal;

		a,
		a:link,
		a:visited {
			font-family: $font-reg;
			color: $title-gray;
		}
	}

	// mobile nav
	&:not(.decision-hub) .mobile-nav-toggle-label {

		span {
			background: $white;
		}

		@include breakpoint(2) {
			display: none;
		}

		@include breakpoint(1) {
			display: flex;
			flex: 0 1 30px;
			order: 1;
		}

		#header-invite-count {
			display: none !important;
		}
	}

	&:not(.decision-hub) .slide-out-menu {
		top: 74px;
		height: 40vh;
		width: 256px;
		-webkit-border-radius: 0 0 10px;
		-moz-border-radius: 0 0 10px;
		border-radius: 0 0 10px;
		overflow-y: hidden;

		.mobile-menu-item {
			a {
				color: $title-gray;
			}	
		}

		&--tabs,
		.user-invites-link {
			display: none !important;
		}
	}

	// footer
	.page-footer {
		margin-top: 0;
		background: $white;
	}
}

.decision-hub {
	#headerbox.page-header {
		background: $white;
	}
	.dp-hub-page-title-heading-container {
		padding: 0px 20px;
		display: flex;
	}
	.dp-title-block {
		justify-content: center;
		align-items: center;
		color: $white;
		margin: 34px auto 63px;
		cursor: auto;
		@include breakpoint(3) {
			display: flex;
			align-items: center;
			flex-direction: row;
		}
		@include breakpoint(1) {
			display: flex;
			align-items: center;
			flex-direction: row;
			margin: 24px auto 33px;
		}
		.dp-title {
			font-family: $font-semi;
			font-size: 23px;
			height: 22px;
			line-height: 0.96;
			width: auto;
			@include breakpoint(1) {
				font-size: 16px;
				height: 14px;
				line-height: 0.88;
			}
		}
	}
}
// DP bg without pseudo element 
.decision-tree {
	#headerbox.page-header {
		@include breakpoint(4) {
			background: none;
			box-shadow: none;
		}
		@include breakpoint(3) {
			background: none;
			box-shadow: none;
		}
	}
}